import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../../../../../enums/tools';
import { MarkingLayer } from '../marking-layer';
import TextAnnotationLayer from '../text-annotation';

export default function AnnotationLayers({ pageNumbersToShow, bookPages, isLoading, pagesRendered, viewportTransform, currentTool, scrollContainer }) {
  const layers = [
    <MarkingLayer
      key={`markings-${pageNumbersToShow.join('-')}`}
      bookPages={bookPages}
      isLoading={isLoading}
      pagesRendered={pagesRendered}
      viewportTransform={viewportTransform}
      scrollContainer={scrollContainer}
    />,
    <TextAnnotationLayer
      key={pageNumbersToShow.join('-')}
      viewportTransform={viewportTransform}
      pageWidth={bookPages[0].width}
      pageHeight={bookPages[0].height}
      scrollContainer={scrollContainer}
    />,
  ];

  return currentTool === Tools.TEXT_ANNOTATION ? layers : layers.reverse();
}

AnnotationLayers.propTypes = {
  pageNumbersToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  bookPages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pagesRendered: PropTypes.array.isRequired,
  viewportTransform: PropTypes.array.isRequired,
  currentTool: PropTypes.string.isRequired,
  scrollContainer: PropTypes.object,
};
