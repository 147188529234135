import { arrayOf, bool, number, shape, string } from 'prop-types';
import React, { useContext, useLayoutEffect } from 'react';
import ContentCounterContext from '../../context/ContentCounterContext';
import { MobileSizeContext } from '../../context/MobileSizeContext';
import AnnotationSetsButton from './items/AnnotationSetsButton';
import ManualButton from './items/manual';
import SolutionsPanel from './items/solutions';
import ToggleMarkingsButton from './items/ToggleMarkingsButton';
import ZoomButtons from './items/zoom';

// Manages counts set in teacher dock itself
function useContentCounter({ showSolutionItems, showAnnotationSetItem, showManualItem }) {
  const { increaseDividersCounterBy, increaseItemsCounterBy } = useContext(ContentCounterContext);

  useLayoutEffect(() => {
    if (showAnnotationSetItem || showManualItem) {
      increaseDividersCounterBy(1);

      if (showAnnotationSetItem) increaseItemsCounterBy(2);
      if (showManualItem) increaseItemsCounterBy(1);
    }

    return () => {
      if (showAnnotationSetItem || showManualItem) {
        increaseDividersCounterBy(-1);

        if (showAnnotationSetItem) increaseItemsCounterBy(-2);
        if (showManualItem) increaseItemsCounterBy(-1);
      }
    };
  }, [increaseDividersCounterBy, increaseItemsCounterBy, showAnnotationSetItem, showManualItem]);

  useLayoutEffect(() => {
    if (showSolutionItems) {
      increaseDividersCounterBy(1);
    }

    return () => {
      if (showSolutionItems) {
        increaseDividersCounterBy(-1);
      }
    };
  }, [increaseDividersCounterBy, showSolutionItems]);
}

const root = 'pbb-dock';

function TeacherDock({ showSolutionItems, showManualItem, showAnnotationSetItem, digibook }) {
  const { isMobileWidth } = useContext(MobileSizeContext);

  useContentCounter({ showSolutionItems, showAnnotationSetItem, showManualItem });

  return (
    <>
      {showAnnotationSetItem && (
        <>
          <li>
            <AnnotationSetsButton />
          </li>
          {!isMobileWidth && (
            <>
              <li>
                <ToggleMarkingsButton />
              </li>
              <li className="pbb-dock__wrapper__divider" />
            </>
          )}
        </>
      )}
      {showManualItem && (
        <>
          <li>
            <ManualButton manualType={digibook.manualType} />
          </li>
          {!isMobileWidth && <li className="pbb-dock__wrapper__divider" />}
        </>
      )}
      {showSolutionItems && (
        <>
          <li className={`${root}__solutions`}>
            <SolutionsPanel digibook={digibook} isMobileWidth={isMobileWidth} />
          </li>
          {!isMobileWidth && <li className={`${root}__wrapper__divider`} />}
        </>
      )}
      <li>
        <ZoomButtons isMobileWidth={isMobileWidth} />
      </li>
    </>
  );
}

TeacherDock.propTypes = {
  showSolutionItems: bool.isRequired,
  showManualItem: bool.isRequired,
  showAnnotationSetItem: bool.isRequired,
  digibook: shape({
    answerLayer: string,
    teacherFeaturesEnabled: bool.isRequired,
    allowedRanges: arrayOf(
      shape({
        from: number.isRequired,
        to: number.isRequired,
      }),
    ),
    totalPages: number.isRequired,
    manualType: string,
  }),
};

export default TeacherDock;
