import { number, shape, string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Book from './book';

import withDimensions from '../../../../hocs/with-dimensions';

import { gotoNextPage, gotoPreviousPage } from '../../../../actions/navigation';

import { getCurrentDigibook } from '../../../../selectors/digibooks';
import { getAnswerLayerPagesToShow, getPageNumbersToShow, getVisiblePages, shouldRenderScroll, shouldRenderSolutionsPage } from '../../../../selectors/rendering';

import { UserSettingsContext } from '../../context/user-settings-context';
import { MobileSizeContext } from '../../context/MobileSizeContext';

import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../enums/analytics';
import tools from '../../../../enums/tools';
import useAnalytics from '../../../../hooks/useAnalytics';
import { getCurrentTool } from '../../../../selectors/tools';
import { PageEventContext } from '../../context/PageEventContext';
import useVisibleAnswerSets from './use-answer-sets-for-visible-pages';
import useVisibleLinkAreas from './use-link-areas-for-visible-pages';
import usePageProvider from './use-page-provider';
import AnchorPosition from '../../../../enums/anchorposition';
import { getViewMode } from '../../../../selectors/navigation';
import ViewMode from '../../../../enums/ViewMode';

export function BookContainer(props) {
  const { digibook, dimensions } = props;

  const dispatch = useDispatch();

  const pageNumbersToShow = useSelector(getPageNumbersToShow);
  const visiblePages = useSelector(getVisiblePages);
  const answersToShow = useSelector(getAnswerLayerPagesToShow);
  const { manualMargins } = useSelector(getCurrentDigibook);
  const renderSolutionsPage = useSelector(shouldRenderSolutionsPage);
  const renderScroll = useSelector(shouldRenderScroll);
  const currentTool = useSelector(getCurrentTool);
  const viewMode = useSelector(getViewMode);

  const analytics = useAnalytics();

  const {
    images: [bookImages, answerImages],
    text: [bookText, answerText],
    manual: manualImages,
  } = usePageProvider(digibook.id, digibook.systemToken, digibook.totalPages, pageNumbersToShow, answersToShow);

  const visibleLinkAreas = useVisibleLinkAreas(digibook, visiblePages);
  const visibleAnswerSets = useVisibleAnswerSets(digibook, visiblePages);

  const { capturePageEvent } = useContext(PageEventContext);
  const { isMobileHeight, isMobileWidth } = useContext(MobileSizeContext);

  // Capture an event on initial load without subLocationId
  useEffect(() => {
    capturePageEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function keyNavigationHandler(ev) {
      const popupNotes = document.querySelectorAll('.popup-note');
      const noteFocused = popupNotes.length && [...popupNotes].some(popupNote => popupNote.contains(document.activeElement));
      const modalShown = document.querySelectorAll('.ngdialog-overlay').length !== 0 || document.querySelectorAll('.bc-dialog__backdrop').length !== 0;

      if (currentTool === tools.TEXT_ANNOTATION || modalShown || noteFocused) {
        return;
      }

      switch (ev.key) {
        case 'Up':
        case 'ArrowUp':
        case 'PageDown':
          if (renderScroll) break; // else fall through
        case 'Left':
        case 'ArrowLeft':
          ev.preventDefault();
          capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.KEYBOARD);
          dispatch(gotoPreviousPage());
          break;
        case 'Down':
        case 'ArrowDown':
        case 'PageUp':
          if (renderScroll) break; // else fall through
        case 'Right':
        case 'ArrowRight':
          ev.preventDefault();
          capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.KEYBOARD);
          dispatch(gotoNextPage());
          break;
        default:
          break;
      }
    }

    window.addEventListener('keyup', keyNavigationHandler);

    return () => window.removeEventListener('keyup', keyNavigationHandler);
  }, [capturePageEvent, dispatch, currentTool, renderScroll]);

  const swipeLeft = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.GESTURES);
    dispatch(gotoNextPage());
  };

  const swipeRight = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.GESTURES);
    dispatch(gotoPreviousPage());
  };

  const captureSameDigibook = () => capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.LINKAREA);

  const captureExternalDigibook = async (objectId, superModuleId) => {
    await Promise.all([
      analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.DIGIBOOK_ACCESSED,
        objectId,
        subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.LINKAREA,
      }),
      analytics.capture({
        eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
        objectId: superModuleId,
        subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.BOOK_SWITCHER,
      }),
    ]);
  };

  const toggledSolutionsPage = useRef(false);
  const [scaleCanvasToFit, setScaleCanvasToFit] = useState(false);

  useEffect(() => {
    toggledSolutionsPage.current = true;
  }, [renderSolutionsPage]);

  useEffect(() => {
    if (toggledSolutionsPage.current) {
      setScaleCanvasToFit(true);

      toggledSolutionsPage.current = false;
    }
  }, [answerImages]);

  useEffect(() => {
    if (scaleCanvasToFit) {
      setScaleCanvasToFit(false);
    }
  }, [scaleCanvasToFit]);

  const { sidebarAnchor, isSidebarOpen, setSidebarOpened } = useContext(UserSettingsContext);

  const [responsiveSidebarAnchor, updateSidebarAnchor] = useState(sidebarAnchor);

  useEffect(() => {
    updateSidebarAnchor(sidebarAnchor);

    if (isMobileHeight) {
      updateSidebarAnchor(AnchorPosition.TOP);
      setSidebarOpened(false);
    }
  }, [isMobileHeight, sidebarAnchor, setSidebarOpened]);

  useEffect(() => {
    if (!(isMobileHeight || isMobileWidth) && viewMode === ViewMode.SCROLL) document.body.classList.add('scroll-view');
    else document.body.classList.remove('scroll-view');
  }, [isMobileHeight, isMobileWidth, viewMode]);

  return (
    <Book
      digibookId={digibook.id}
      disableAnswerLayerBlend={digibook.disableAnswerLayerBlend}
      dimensions={dimensions}
      bookPages={bookImages}
      answerPages={answerImages}
      manualPages={manualImages}
      manualMargins={manualMargins}
      sidebarAnchor={responsiveSidebarAnchor}
      activeDrawerAnchorSide={isSidebarOpen ? responsiveSidebarAnchor : undefined}
      pageNumbersToShow={pageNumbersToShow}
      visibleLinkAreas={visibleLinkAreas}
      visibleAnswerSets={visibleAnswerSets}
      bookText={bookText}
      answerText={answerText}
      swipeLeft={swipeLeft}
      swipeRight={swipeRight}
      captureSameDigibook={captureSameDigibook}
      captureExternalDigibook={captureExternalDigibook}
      scaleCanvasToFit={scaleCanvasToFit}
      isMobileHeight={isMobileHeight}
    />
  );
}

BookContainer.propTypes = {
  digibook: shape({
    id: string.isRequired,
    systemToken: string,
  }).isRequired,
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withDimensions(BookContainer);
