import { arrayOf, bool, elementType, number, shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PlayerMode from '../../../../enums/playerMode';
import { getPlayerMode } from '../../../../selectors/player';
import { isTeacherAlike } from '../../../../selectors/user';
import ContentCounterContext, { ContentCounterProvider } from '../../context/ContentCounterContext';
import { MobileSizeContext } from '../../context/MobileSizeContext';
import ViewModesButton from './items/ViewModesButton';
import StudentDock from './StudentDock';
import TeacherDock from './TeacherDock';
import FlyOutContext from './flyOutContext';

const removePixelUnitFromStyle = cssValue => Number(cssValue.replace('px', ''));
const WHITESPACE_DOCK = 32;
export const COMPACT_DOCK_HEIGHT = 64;

const calculateDockWidth = (amountOfItems, amountOfDividers, dockElement, isMobileHeightActive) => {
  const sidebarComputedStyle = window.getComputedStyle(document.documentElement);
  const dockComputedStyle = window.getComputedStyle(dockElement);

  const sidebarWidth = !isMobileHeightActive ? removePixelUnitFromStyle(sidebarComputedStyle.getPropertyValue('--sidebar-width')) : 0;
  const dockPaddingInline = removePixelUnitFromStyle(dockComputedStyle.getPropertyValue('--dock-padding-inline'));

  const dockItemWidth = !isMobileHeightActive
    ? removePixelUnitFromStyle(dockComputedStyle.getPropertyValue('--dock-item-width'))
    : removePixelUnitFromStyle(dockComputedStyle.getPropertyValue('--dock-icon-button-size'));

  const dockDividerWidth = removePixelUnitFromStyle(dockComputedStyle.getPropertyValue('--dock-divider-width'));
  const dockDividerMargin = removePixelUnitFromStyle(dockComputedStyle.getPropertyValue('--dock-divider-margin-inline'));

  return (
    sidebarWidth +
    WHITESPACE_DOCK +
    (dockPaddingInline + amountOfItems * dockItemWidth + amountOfDividers * (dockDividerWidth + dockDividerMargin * 2) + dockPaddingInline) +
    WHITESPACE_DOCK +
    sidebarWidth
  );
};

function getStartAdornmentItemSize(dockElement) {
  if (!dockElement) return 0;

  const dockComputedStyle = window.getComputedStyle(dockElement);

  return removePixelUnitFromStyle(dockComputedStyle.getPropertyValue('--dock-icon-button-size'));
}

function getNavigationItemSize() {
  const sidebarComputedStyle = window.getComputedStyle(document.documentElement);

  return removePixelUnitFromStyle(sidebarComputedStyle.getPropertyValue('--sidebar-width'));
}

const Dock = ({ digibook, StartAdornment, EndAdornment }) => {
  const teacherAlike = useSelector(isTeacherAlike);
  const playerMode = useSelector(state => getPlayerMode(state));
  const isWhitepageMode = playerMode === PlayerMode.WHITEPAGE;
  const [openFlyOutType, setOpenFlyOutType] = useState(undefined);

  const dockRef = useRef(null);

  const { setMaxDesktopWidth, isMobileHeight, isMobileWidth } = useContext(MobileSizeContext);
  const { itemsCount, dividersCount, increaseDividersCounterBy, increaseItemsCounterBy } = useContext(ContentCounterContext);

  const showSolutionItems = Boolean(digibook && digibook.answerLayer && !isWhitepageMode);
  const showManualItem = Boolean(digibook && digibook.manual && !isWhitepageMode);
  const showAnnotationSetItem = !isWhitepageMode && teacherAlike;

  const [startAdornmentWidth, setStartAdornmentWidth] = useState(0);
  const [endAdornmentWidth, setEndAdornmentWidth] = useState(0);

  const handleResize = useCallback(() => {
    const dockWidth = calculateDockWidth(itemsCount, dividersCount, dockRef.current, isMobileHeight);

    const totalWidth = startAdornmentWidth + dockWidth + endAdornmentWidth;

    setMaxDesktopWidth(totalWidth);
  }, [dividersCount, endAdornmentWidth, isMobileHeight, itemsCount, setMaxDesktopWidth, startAdornmentWidth]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const calculateStartAdornmentWidth = useCallback(count => {
    const startAdornmentItemSize = getStartAdornmentItemSize(dockRef.current);

    const width = count * startAdornmentItemSize;
    setStartAdornmentWidth(width);
  }, []);

  const calculateEndAdornmentWidth = useCallback(count => {
    const navigationItemSize = getNavigationItemSize();

    const width = count * navigationItemSize;
    setEndAdornmentWidth(width);
  }, []);

  // counts for view modes buttons
  useLayoutEffect(() => {
    if (!isWhitepageMode) {
      increaseDividersCounterBy(1);
      increaseItemsCounterBy(1);
    }

    return () => {
      if (isWhitepageMode) {
        increaseDividersCounterBy(-1);
        increaseItemsCounterBy(-1);
      }
    };
  }, [increaseDividersCounterBy, increaseItemsCounterBy, isWhitepageMode]);

  return (
    <FlyOutContext.Provider value={{ openFlyOutType, setOpenFlyOutType }}>
      <div className="pbb-dock" data-testid="dock" ref={dockRef}>
        {Boolean(StartAdornment) && (
          <div id="dock-start-adornment" className="pbb-dock__adornment pbb-dock__adornment--start" data-testid="dock-start-adornment">
            <StartAdornment calculateTotalWidth={calculateStartAdornmentWidth} />
          </div>
        )}
        <ul className="pbb-dock__wrapper">
          {teacherAlike ? (
            <TeacherDock digibook={digibook} showSolutionItems={showSolutionItems} showManualItem={showManualItem} showAnnotationSetItem={showAnnotationSetItem} />
          ) : (
            <StudentDock digibook={digibook} showSolutionItems={showSolutionItems} />
          )}
          {!isWhitepageMode && !isMobileWidth && (
            <>
              <li className="pbb-dock__wrapper__divider" />
              <li>
                <ViewModesButton />
              </li>
            </>
          )}
        </ul>
        {Boolean(EndAdornment) && (
          <div id="dock-end-adornment" className="pbb-dock__adornment pbb-dock__adornment--end" data-testid="dock-end-adornment">
            <EndAdornment calculateTotalWidth={calculateEndAdornmentWidth} />
          </div>
        )}
      </div>
    </FlyOutContext.Provider>
  );
};

const digibookPropType = shape({
  answerLayer: string,
  teacherFeaturesEnabled: bool.isRequired,
  allowedRanges: arrayOf(
    shape({
      from: number.isRequired,
      to: number.isRequired,
    }),
  ),
  totalPages: number.isRequired,
});

Dock.propTypes = {
  digibook: digibookPropType,
  StartAdornment: elementType,
  EndAdornment: elementType,
};

function ResponsiveDock(props) {
  return (
    <ContentCounterProvider>
      <Dock {...props} />
    </ContentCounterProvider>
  );
}

ResponsiveDock.propTypes = Dock.propTypes;

export default ResponsiveDock;
