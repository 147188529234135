/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/control-has-associated-label */
import BookExclamationMarkIcon from '@pelckmans/business-components/icons/BookExclamationMark';
import classNames from 'classnames';
import Hammer from 'hammerjs';
import { array, arrayOf, bool, func, number, object, objectOf, oneOf, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { SIDEBAR_WIDTH } from '../sidebar/constants';
import { getTotalPagesForDigibook } from '../../../../selectors/digibooks';
import { getZoomLevel, getZoomToFitMode } from '../../../../selectors/navigation';
import { getIsRendered } from '../../../../selectors/player';
import { getAnswerShapesForCurrentPages, shouldRenderScroll, shouldRenderSolutionsPage } from '../../../../selectors/rendering';
import { getAnswerStepperMode, getCurrentTool, getTools } from '../../../../selectors/tools';

import { linkAreaWithMedialinksClicked } from '../../../../actions/dialog';
import { disableZoomToFitMode, hideAnswerAreaFor, hideStepperAnswer, revealAnswerAreaFor, revealStepperAnswer, setCurrentPage, setZoomLevel } from '../../../../actions/navigation';
import { bookLayerRendered } from '../../../../actions/player';
import { createMarking, createMarkings, setCurrentTool } from '../../../../actions/tools';

import LinkAreaLinkTypes from '../../../../enums/linkarealinktype';
import Position from '../../../../enums/position';
import shapeActions from '../../../../enums/shapeActions';
import Tools from '../../../../enums/tools';
import ZoomLevel from '../../../../enums/zoomLevel';

import api from '../../../../services/api';
import FabricService from '../../services/fabric-service';

import { calculateSpreadForPageNumbers } from '../../../../utils/calculateSpreadForPageNumbers';
import isOdd from '../../../../utils/isOdd';
import * as Url from '../../../../utils/url';

import spinner from '../../../../../assets/images/spinner.svg';
import { PORTAAL_URL } from '../../../../constants/constants';
import AnchorPosition from '../../../../enums/anchorposition';
import Confirmation from '../dialogs/confirmation';
import AnnotationLayers from './annotation-layers';
import TextLayer from './text-selection/text-layer';

const modals = { ERROR: 'error', POPUP: 'popup' };
const SCROLL_TRACKPAD_ACTION_THRESHOLD = 80;
const SCROLL_MOUSE_ACTION_THRESHOLD = 5;
const SCROLL_TOUCH_ACTION_THRESHOLD = 200;
const SCROLL_MOUSE_CLICK_ACTION_THRESHOLD = 300;
const SCROLL_MOUSE_CLICK_ACTION_ERROR_MARGIN = 1;
const DELAYED_SCROLL_ACTION_TIMEOUT = 500;

class Book extends React.PureComponent {
  constructor(props) {
    super(props);

    this.container = React.createRef();

    this.answerToBlink = undefined;
    this.updatePromise = undefined;

    this.digibookLicenses = {};
    this.isInitialized = false;

    this.state = {
      currentAnswerSet: undefined,
      currentAnswerIndex: undefined,
      openModal: undefined,
      pagesRendered: [],
      renderState: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    const {
      dimensions: { width, height },
      pageNumbersToShow,
      activeDrawerAnchorSide,
      disableAnswerLayerBlend,
      manualMargins,
      sidebarAnchor,
      renderScroll,
    } = this.props;

    this.updateExternalDigibookLicenses();
    this.fabric = new FabricService('the-fabric-canvas', pageNumbersToShow.length, undefined, undefined, this.setRenderState);
    this.fabric.initialize(height, width, disableAnswerLayerBlend, sidebarAnchor);
    this.fabric.addDragListeners(
      {
        [Tools.ANSWER_REVEAL]: this.objectCreatedHandler,
        [Tools.ANSWER_HIDE]: this.objectCreatedHandler,
        [Tools.ZOOM_SELECT]: this.zoomSelectionCreatedHandler,
        [Tools.SELECTION_ERASER]: this.onShapeDrawn,
      },
      this.updateViewport,
      this.disableZoomToFit,
      renderScroll,
    );

    this.addSwipeListeners();
    this.fabric.setDrawerOpenSide(activeDrawerAnchorSide);
    this.fabric.addPinchListeners(this.pinchHandler);
    this.updateViewport();
    if (manualMargins) this.fabric.setManualMargins(manualMargins);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pageNumbersToShow,
      dimensions,
      currentTool,
      zoomLevel,
      zoomToFitMode,
      answerShapes,
      tools,
      bookPages,
      answerPages,
      manualPages,
      activeDrawerAnchorSide,
      answerStepperMode,
      visibleLinkAreas,
      externalDigibookIds,
      scaleCanvasToFit,
      sidebarAnchor,
      isSolutionsPageVisible,
      renderScroll,
      isMobileHeight,
    } = this.props;

    const {
      dimensions: oldDimensions,
      pageNumbersToShow: prevPages,
      zoomLevel: oldZoomLevel,
      zoomToFitMode: wasZoomedToFit,
      answerShapes: prevAnswerShapes,
      tools: prevTools,
      bookPages: prevBookPages,
      answerPages: prevAnswerPages,
      manualPages: prevManualPages,
      activeDrawerAnchorSide: prevActiveDrawerAnchorSide,
      answerStepperMode: prevAnswerStepperMode,
      currentTool: prevTool,
      visibleLinkAreas: prevVisibleLinkAreas,
      externalDigibookIds: prevExternalDigibookIds,
      sidebarAnchor: prevSidebarAnchor,
      renderScroll: prevRenderScroll,
      isMobileHeight: prevIsMobileHeight,
    } = prevProps;

    const { currentAnswerIndex, currentAnswerSet } = this.state;
    const { currentAnswerIndex: prevAnswerIndex, currentAnswerSet: prevAnswerSet } = prevState;
    const answerIndexChanged = currentAnswerIndex !== prevAnswerIndex;

    const dimensionsChanged = oldDimensions && (dimensions.width !== oldDimensions.width || dimensions.height !== oldDimensions.height);
    const pageNumbersToShowChanged = pageNumbersToShow !== prevPages;
    const zoomChanged = oldZoomLevel !== zoomLevel;
    const hasZoomedToFit = zoomToFitMode && !wasZoomedToFit;
    const bookPagesChanged = bookPages !== prevBookPages;
    const answerPagesChanged = answerPages !== prevAnswerPages;
    const drawerPositionChanged = activeDrawerAnchorSide !== prevActiveDrawerAnchorSide;
    const answerStepperModeChanged = answerStepperMode !== prevAnswerStepperMode;
    const answersHidden = Object.values(prevAnswerShapes).some(shapes => shapes.length > 0) && Object.values(answerShapes).every(shapes => shapes.length === 0);
    const answerShapesChanged = answerShapes !== prevAnswerShapes;
    const answersRevealed = Object.values(answerShapes).some(pageArr => pageArr.length > 0);
    const manualPagesChanged = manualPages !== prevManualPages;
    const externalDigibooksChanged = externalDigibookIds.some(id => !prevExternalDigibookIds.includes(id));
    const sidebarAnchorChanged = sidebarAnchor !== prevSidebarAnchor;
    const renderScrollChanged = renderScroll !== prevRenderScroll;
    const isMobileHeightChanged = isMobileHeight !== prevIsMobileHeight;

    if (isMobileHeightChanged) {
      this.fabric.setIsMobileHeight(isMobileHeight);
    }

    if (sidebarAnchorChanged) this.fabric.setSidebarAnchor(sidebarAnchor);

    if (externalDigibooksChanged) {
      this.updateExternalDigibookLicenses();
    }

    if (pageNumbersToShowChanged) {
      this.fabric.setAmountOfVisiblePages(pageNumbersToShow.length);
      this.showLoader();

      if (renderScroll) {
        this.isPrevPage = Boolean(pageNumbersToShow[0] - prevPages[0] < 0);
      }
    }

    if (scaleCanvasToFit) this.fabric.scaleCanvasToFit(renderScroll);

    if (sidebarAnchorChanged && isSolutionsPageVisible) {
      this.renderLayers();
      this.drawLinks();
      if (answersRevealed) this.clipAnswerPages();
      if (currentAnswerSet) this.setSelectedAnswerSet(undefined, true);

      this.fabric.scaleCanvasToFit(renderScroll);
    }

    if (bookPagesChanged || answerPagesChanged || manualPagesChanged) {
      this.renderLayers();
      this.container.current.focus();
    }

    if (bookPagesChanged) {
      if (this.isPrevPage) {
        this.container.current.scrollTop = this.container.current.scrollHeight;
      } else {
        this.container.current.scrollTop = 0;
      }

      this.hideLoader();

      this.fabric.scaleCanvasToFit(renderScroll, zoomLevel);

      this.drawLinks();
      if (answersRevealed) this.clipAnswerPages();
      if (currentAnswerSet) this.setSelectedAnswerSet(undefined, true);
    } else if (answerPagesChanged) {
      this.drawLinks();
    } else if (manualPagesChanged) {
      this.drawLinks();
      if (answersRevealed) this.clipAnswerPages();
      if (currentAnswerSet) this.setSelectedAnswerSet(undefined, true);

      this.fabric.scaleCanvasToFit(renderScroll);
      this.updateViewport();
    } else if (hasZoomedToFit && this.pagesRendered === pageNumbersToShow) {
      this.fabric.scaleCanvasToFit(renderScroll, zoomLevel);
      this.updateViewport();
    }
    // Place in else block when other cases arises which have to be done when no answerPages or bookPages changed

    if (dimensionsChanged) {
      this.fabric.handleCanvasResize(dimensions);
      if (renderScroll && oldDimensions.width !== 0 && oldDimensions.height !== 0) {
        this.fabric.resizeCanvasForScroll();
        this.fabric.scaleCanvasToFit(renderScroll);
      }

      if (isMobileHeight && !this.isInitialized) {
        this.fabric.setCanvasDimensions(dimensions.height, dimensions.width + SIDEBAR_WIDTH);
        this.isInitialized = true;
      }

      this.updateViewport();
    }

    if (bookPagesChanged || renderScrollChanged) {
      if (renderScroll) {
        this.fabric.resizeCanvasForScroll();
        this.fabric.scaleCanvasToFit(renderScroll);

        this.fabric.removeMouseMoveDragListener();
        this.addScrollListeners();
      } else if (prevRenderScroll) {
        this.fabric.handleCanvasResize(dimensions, renderScroll);

        this.fabric.addMouseMoveDragListener();
        this.removeScrollListeners();
      }
      this.updateViewport();
    }

    if (currentAnswerSet && answersHidden && currentAnswerIndex !== -1) {
      // differentiate between answer stepper being deselected and answers being hidden.
      this.setSelectedAnswerSet(undefined, true);
    }

    if (zoomChanged) {
      this.fabric.setZoom(zoomLevel);
      this.updateViewport();
    }

    if (zoomLevel === ZoomLevel.BASE_ZOOM_LEVEL && zoomToFitMode && this.pagesRendered === pageNumbersToShow && !renderScroll) {
      this.fabric.shiftViewportForDrawer(activeDrawerAnchorSide, this.updateViewport);
    }

    if (drawerPositionChanged) {
      this.fabric.setDrawerOpenSide(activeDrawerAnchorSide);
      this.updateViewport();
    }

    if (answerStepperModeChanged || bookPagesChanged || answerPagesChanged || currentAnswerSet !== prevAnswerSet || answerIndexChanged) {
      this.renderAnswerSetIcons();
    }

    if (!pageNumbersToShowChanged && answerPages.length > 0 && (answerShapesChanged || answerPagesChanged)) {
      this.clipAnswerPages();
      this.drawLinks();
      delete this.answerToBlink;
    }

    if (tools !== prevTools || currentTool !== prevTool) {
      this.fabric.setCurrentTool(currentTool, { ...tools[currentTool] });

      if (!bookPagesChanged) this.setSelectedAnswerSet(undefined, true);
    }

    if (currentTool !== prevTool || visibleLinkAreas !== prevVisibleLinkAreas) {
      this.drawLinks();
      this.renderAnswerSetIcons();
    }

    if (currentTool === Tools.POINTER) {
      this.fabric.fabricCanvas.upperCanvasEl.style.touchAction = 'pan-y';
    } else {
      this.fabric.fabricCanvas.upperCanvasEl.style.touchAction = 'none';
    }

    this.fabric.renderAll();
  }

  componentWillUnmount() {
    this.fabric.dispose();
    if (this.eventManager) this.eventManager.destroy();
    if (this.delayedScrollAction) {
      clearTimeout(this.delayedScrollAction);
      this.delayedScrollAction = undefined;
    }
    this.removeScrollListeners();
  }

  disableZoomToFit = () => {
    const { userHasPanned, zoomToFitMode } = this.props;

    if (zoomToFitMode) userHasPanned();
  };

  getSpreadSpecs = () => {
    const { pageNumbersToShow, totalPages } = this.props;
    const isSinglePage = pageNumbersToShow.length === 1;
    const isRightPage = pageNumbersToShow[0] !== 1 && isOdd(pageNumbersToShow[0]);
    const isStandalonePage =
      pageNumbersToShow[0] === 0 || // cover
      pageNumbersToShow[0] === 1 || // first page
      pageNumbersToShow[0] === totalPages + 1 || // backcover
      (pageNumbersToShow[0] === totalPages && !isRightPage); // last page

    return {
      isSinglePage,
      isRightPage,
      isStandalonePage,
    };
  };

  setRenderState = state => {
    this.setState({ renderState: state });
  };

  setSelectedAnswerSet = (setId, forceUpdate) => {
    const { currentTool } = this.props;
    const { currentAnswerSet } = this.state;

    if (currentTool === Tools.POINTER || forceUpdate) {
      this.setState({ currentAnswerSet: currentAnswerSet === setId ? undefined : setId, currentAnswerIndex: undefined });
    }
  };

  renderAnswerSetIcons = () => {
    const { visibleAnswerSets, answerStepperMode } = this.props;
    const { currentAnswerIndex, currentAnswerSet } = this.state;
    const { isStandalonePage, isSinglePage, isRightPage } = this.getSpreadSpecs();

    if (answerStepperMode) {
      this.fabric.renderAnswerSteppers(
        visibleAnswerSets,
        isStandalonePage,
        isSinglePage,
        isRightPage,
        currentAnswerSet,
        currentAnswerIndex,
        this.setSelectedAnswerSet,
        this.incrementCurrentAnswerIndex,
        this.decrementCurrentAnswerIndex,
      );
    } else {
      this.fabric.cleanUpAnswerSteppers();
    }
  };

  onShapeDrawn = drawnShape => {
    const { markingCreated, pageNumbersToShow, totalPages } = this.props;
    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    const shapeToAdd = this.fabric.offsetDrawings(drawnShape, isSinglePage, isRightPage);

    const clonedShape = shapeToAdd.toObject();
    clonedShape.meta = shapeToAdd.meta;

    markingCreated(clonedShape, calculateSpreadForPageNumbers(pageNumbersToShow, totalPages));
  };

  renderLayers = () => {
    const { bookPages, answerPages, bookRendered, pageNumbersToShow, manualPages, isSolutionsPageVisible } = this.props;

    this.fabric.clearPageCache();
    this.fabric.removeAllObjects();

    bookPages.forEach((image, index) => {
      const position = index === 0 ? Position.LEFT : Position.RIGHT;
      this.fabric.renderBookPage(image, position, this.determineLeftOrRightPosition(pageNumbersToShow[index]), isSolutionsPageVisible);
    });

    answerPages.forEach((image, index) => {
      const position = index === 0 ? Position.LEFT : Position.RIGHT;
      this.fabric.cacheAnswerPage(image, position, isSolutionsPageVisible);
    });

    manualPages.forEach((image, index) => {
      const position = index === 0 ? Position.LEFT : Position.RIGHT;

      const { isRightPage } = this.getSpreadSpecs();

      if (image !== null) {
        this.fabric.renderManualPage(image, position, isRightPage, manualPages.filter(x => x !== null).length, isSolutionsPageVisible);
      }
    });

    if (bookPages.length > 0) {
      bookRendered(this.fabric.getSinglePageDimensions());
      this.pagesRendered = pageNumbersToShow;
      this.setState({ pagesRendered: pageNumbersToShow });
    }
  };

  drawLinks = () => {
    const { visibleLinkAreas, currentTool } = this.props;
    const { isStandalonePage, isSinglePage, isRightPage } = this.getSpreadSpecs();

    const applyEventsToLinks = currentTool !== Tools.ANNOTATION;

    this.fabric.renderAreasAndAddListeners(visibleLinkAreas, isStandalonePage, isSinglePage, isRightPage, this.linkAreaClickHandler, applyEventsToLinks);
  };

  objectCreatedHandler = rect => {
    const { revealArea, hideArea, pageNumbersToShow, currentTool, totalPages } = this.props;
    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    const shapeToAdd = this.fabric.offsetShape(rect, isSinglePage, isRightPage);

    switch (currentTool) {
      case Tools.ANSWER_REVEAL: {
        revealArea(shapeToAdd, pageNumbersToShow, totalPages);
        break;
      }
      case Tools.ANSWER_HIDE: {
        hideArea(shapeToAdd, pageNumbersToShow, totalPages);
        break;
      }
      default:
        hideArea(shapeToAdd, pageNumbersToShow, totalPages);
        break;
    }
  };

  zoomSelectionCreatedHandler = nextZoom => {
    const { setZoom, setTool } = this.props;

    setZoom(nextZoom);
    setTool(Tools.POINTER);
    this.updateViewport();
  };

  saveTextHighlights = shapes => {
    const { markingsCreated, pageNumbersToShow, totalPages } = this.props;

    const rects = FabricService.getSelectionShapes(shapes);
    markingsCreated(rects, calculateSpreadForPageNumbers(pageNumbersToShow, totalPages));
    this.fabric.clearTempHighlights();
  };

  renderTempHighlights = shapes => {
    const rects = FabricService.getSelectionShapes(shapes);

    this.fabric.renderTempHighlights(rects);
  };

  linkAreaClickHandler = async opt => {
    const { visibleLinkAreas, setPage, digibookId: currentDigibookId, currentTool, openMedialinksForLinkArea } = this.props;
    const linkArea = visibleLinkAreas.find(x => x.id === opt.target.linkAreaId);
    const { linkType, url, digibookLink, name } = linkArea;

    opt.e.preventDefault();

    if (currentTool === Tools.POINTER) {
      switch (linkType) {
        case LinkAreaLinkTypes.URL: {
          return this.openInNewtabWithFallBack(url, name);
        }
        case LinkAreaLinkTypes.DIGIBOOK: {
          const { captureSameDigibook, captureExternalDigibook } = this.props;
          const { superModuleId, digibook, pageNumber } = digibookLink;

          // same digibook
          if (currentDigibookId === digibook) {
            captureSameDigibook();
            return setPage(pageNumber);
          }

          // check license if they are loaded, otherwise just continue
          if (this.digibookLicenses[digibook] === false) {
            return this.setState({ openModal: { type: modals.ERROR } });
          }

          // open external digibook
          await captureExternalDigibook(digibook, superModuleId);
          const superModuleQ = superModuleId ? `?superModuleId=${superModuleId}` : '';
          const path = `/digibook/${digibook}/${pageNumber}${superModuleQ}`;
          return this.openInNewtabWithFallBack(path, name);
        }
        case LinkAreaLinkTypes.MEDIALINKS:
          {
            const { sidebarAnchor } = this.props;
            const sidebarOffset = sidebarAnchor === AnchorPosition.LEFT ? SIDEBAR_WIDTH : 0;
            const isTouch = opt.e.type && opt.e.type.indexOf('touch') > -1;
            const x = isTouch ? opt.e.changedTouches[0].clientX : opt.e.offsetX + sidebarOffset;
            const y = isTouch ? opt.e.changedTouches[0].clientY : opt.e.offsetY;
            openMedialinksForLinkArea(linkArea, { x, y });
          }
          break;
        default: {
          // ignore
        }
      }
    }

    return false;
  };

  updateViewport = () => {
    const { viewPortTransform } = this.state;
    const fabricVpt = this.fabric.getViewportTransform();
    document.dispatchEvent(
      new CustomEvent('canvas-panned', {
        detail: fabricVpt,
      }),
    );

    if (!viewPortTransform || viewPortTransform.some((item, i) => item !== fabricVpt[i])) {
      this.setState({ viewPortTransform: [...fabricVpt] });
    }
  };

  decrementCurrentAnswerIndex = () => {
    const { visibleAnswerSets, stepperHide } = this.props;
    const { currentAnswerSet, currentAnswerIndex } = this.state;
    const newAnswerIndex = (currentAnswerIndex || 0) - 1;

    if (newAnswerIndex >= -1) {
      const current = visibleAnswerSets.find(x => x.id === currentAnswerSet);
      this.setState({ currentAnswerIndex: newAnswerIndex });
      stepperHide(current, currentAnswerIndex);
    }
  };

  incrementCurrentAnswerIndex = () => {
    const { visibleAnswerSets, stepperReveal } = this.props;
    const { currentAnswerSet, currentAnswerIndex } = this.state;

    const newAnswerIndex = currentAnswerIndex === undefined ? 0 : currentAnswerIndex + 1;

    const current = visibleAnswerSets.find(x => x.id === currentAnswerSet);
    if (current.answers && newAnswerIndex <= current.answers.length - 1) {
      stepperReveal(current, newAnswerIndex);
      this.answerToBlink = `${currentAnswerSet}-${newAnswerIndex}`;
      this.setState({ currentAnswerIndex: newAnswerIndex });
    }
  };

  pinchHandler = zoomFactor => {
    const { setZoom } = this.props;
    setZoom(zoomFactor);
    this.updateViewport();
  };

  updateExternalDigibookLicenses = async () => {
    const { externalDigibookIds } = this.props;

    const unknownDigibooks = externalDigibookIds.filter(id => this.digibookLicenses[id] === undefined);

    if (unknownDigibooks && unknownDigibooks.length > 0) {
      // Apparently this magically turns your query param into an array in the backend.
      const query = unknownDigibooks.map(id => `digibookIds=${id}&`).join('');
      const {
        data: { data: licenseData },
      } = await api.get(`/studio/digibooks/license/check?${query}`);

      unknownDigibooks.forEach(id => {
        const x = licenseData.find(el => el.id === id);
        this.digibookLicenses[id] = x ? x.hasLicense : false;
      });
    }
  };

  // this should be the only correct position determination for the pages
  determineLeftOrRightPosition = pageNumber => {
    const { totalPages } = this.props;

    const standAloneLeftPage =
      pageNumber === 0 || // cover
      pageNumber === 1 || // first page
      pageNumber === totalPages + 1; // backcover

    if (isOdd(pageNumber) && !standAloneLeftPage) return 'right';

    return 'left';
  };

  openInNewtabWithFallBack(url, name) {
    const newTab = window.open(url, '_blank');
    if (newTab) return newTab;
    // opening of the external url failed. Happens sometimes on iOS devices. (timing issue?)
    // If that happened, we will present a popup to the user with a link, that will never fail!
    return this.setState({
      openModal: {
        type: modals.POPUP,
        name,
        url,
      },
    });
  }

  removeScrollListeners() {
    this.container.current.removeEventListener('scroll', this.scrollListener);
    this.container.current.removeEventListener('wheel', this.wheelListener);
    this.container.current.removeEventListener('touchstart', this.touchStartListener);
    this.container.current.removeEventListener('touchmove', this.touchMoveListener);
    this.container.current.removeEventListener('mousedown', this.mouseStartListener);
    this.container.current.removeEventListener('mousemove', this.mouseMoveListener);
    this.container.current.removeEventListener('mouseup', this.mouseEndListener);
  }

  resetScrollOffsetAndAction() {
    this.scrollOffset = 0;
    clearTimeout(this.delayedScrollAction);
    this.delayedScrollAction = undefined;
    this.touchStartY = null;
  }

  createDelayedScrollAction(isSwipeLeft) {
    const { swipeLeft, swipeRight } = this.props;

    this.delayedScrollAction = setTimeout(() => {
      if (isSwipeLeft) {
        swipeLeft();
      } else {
        swipeRight();
      }
      this.resetScrollOffsetAndAction();
    }, DELAYED_SCROLL_ACTION_TIMEOUT);
  }

  addScrollListeners() {
    this.resetScrollOffsetAndAction();
    this.removeScrollListeners();

    this.scrollListener = () => {
      const { scrollHeight, clientHeight, scrollTop } = this.container.current;
      if (scrollTop > 0 && scrollTop + clientHeight < scrollHeight) {
        this.resetScrollOffsetAndAction();
      }
    };

    this.wheelListener = e => {
      this.scrollOffset += 1;
      const threshold = Math.abs(e.deltaY) < 100 ? SCROLL_TRACKPAD_ACTION_THRESHOLD : SCROLL_MOUSE_ACTION_THRESHOLD;

      if (!this.delayedScrollAction && this.scrollOffset >= threshold) {
        this.createDelayedScrollAction(this.container.current.scrollTop !== 0);
      }
    };

    this.touchStartListener = e => {
      this.touchStartY = e.touches[0].clientY;
    };

    this.touchMoveListener = e => {
      const { currentTool } = this.props;
      if (this.touchStartY === null || currentTool !== Tools.POINTER) return;

      const touchCurrentY = e.touches[0].clientY;
      const deltaY = this.touchStartY - touchCurrentY;
      this.scrollOffset = Math.abs(deltaY);

      if (!this.delayedScrollAction && this.scrollOffset >= SCROLL_TOUCH_ACTION_THRESHOLD) {
        this.createDelayedScrollAction(deltaY > 0);
      }
    };

    let startY;
    let scrollStartTop;
    let isDragging = false;

    this.mouseStartListener = e => {
      const { currentTool } = this.props;
      const upperCanvas = this.fabric.fabricCanvas.upperCanvasEl;

      if (e.target !== upperCanvas || currentTool !== Tools.POINTER) return;
      isDragging = true;
      startY = e.clientY;
      scrollStartTop = this.container.current.scrollTop;
      e.preventDefault();
    };

    this.mouseMoveListener = e => {
      if (!isDragging) return;

      const deltaY = e.clientY - startY;
      this.container.current.scrollTop = scrollStartTop - deltaY;

      const { scrollHeight, clientHeight, scrollTop } = this.container.current;

      if (!this.touchStartY) this.touchStartY = deltaY;

      if (scrollTop <= 0 || scrollTop + clientHeight + SCROLL_MOUSE_CLICK_ACTION_ERROR_MARGIN >= scrollHeight) {
        this.scrollOffset = Math.abs(deltaY - this.touchStartY);
      }

      if (!this.delayedScrollAction && this.scrollOffset >= SCROLL_MOUSE_CLICK_ACTION_THRESHOLD) {
        this.createDelayedScrollAction(this.scrollOffset < 0);
      }
    };

    this.mouseEndListener = () => {
      isDragging = false;
    };

    this.container.current.addEventListener('scroll', this.scrollListener); // scroll or scrollend event are not triggered at the top or bottom of the page.
    this.container.current.addEventListener('wheel', this.wheelListener);
    this.container.current.addEventListener('touchstart', this.touchStartListener);
    this.container.current.addEventListener('touchmove', this.touchMoveListener);
    this.container.current.addEventListener('mousedown', this.mouseStartListener);
    this.container.current.addEventListener('mousemove', this.mouseMoveListener);
    this.container.current.addEventListener('mouseup', this.mouseEndListener);
  }

  addSwipeListeners() {
    const { swipeLeft, swipeRight } = this.props;
    this.eventManager = new Hammer(this.container.current);

    this.eventManager.get('swipe').set({ velocity: 1, direction: Hammer.DIRECTION_HORIZONTAL });

    this.eventManager.on('swipeleft', e => {
      const { currentTool } = this.props;
      const upperCanvas = this.fabric.fabricCanvas.upperCanvasEl;

      if (e.pointerType !== 'mouse' && e.target === upperCanvas && currentTool === Tools.POINTER) {
        swipeLeft();
      }
    });
    this.eventManager.on('swiperight', e => {
      const { currentTool } = this.props;
      const upperCanvas = this.fabric.fabricCanvas.upperCanvasEl;

      if (e.pointerType !== 'mouse' && e.target === upperCanvas && currentTool === Tools.POINTER) {
        swipeRight();
      }
    });
  }

  clipAnswerPages() {
    const { answerShapes } = this.props;
    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    this.fabric.clipAnswers(answerShapes, isSinglePage, isRightPage, this.answerToBlink);
  }

  showLoader() {
    this.fabric.removeAllObjects();
    this.setState({ isLoading: true });
  }

  hideLoader() {
    this.setState({ isLoading: false });
  }

  renderConfirmationModal() {
    const { t } = this.props;
    const { openModal = {} } = this.state;

    switch (openModal.type) {
      case modals.ERROR:
        return (
          <Confirmation
            title={t('noAccessConfirmation.title')}
            icon={<BookExclamationMarkIcon />}
            message={t('noAccessConfirmation.message', {
              myAccountLink: Url.join(PORTAAL_URL, t('portaalRoutes.myAccount')),
            })}
            cancellationText={t('noAccessConfirmation.buttons.cancel')}
            onCancel={() => {
              this.setState({ openModal: undefined });
            }}
          />
        );
      case modals.POPUP: {
        const title = `${t('externalLinkModal.title')} ${openModal.name}`;
        const message = `${t('externalLinkModal.messagePartOne')} '${openModal.name}' ${t('externalLinkModal.messagePartTwo')}`;
        return (
          <Confirmation
            title={title}
            icon="icon-exit"
            message={message}
            cancellationText={t('externalLinkModal.buttons.cancel')}
            confirmationText={t('externalLinkModal.buttons.confirm')}
            onCancel={() => {
              this.setState({ openModal: undefined });
            }}
            onConfirm={() => {
              window.open(openModal.url, '_blank');
              this.setState({ openModal: undefined });
            }}
          />
        );
      }
      default:
        return null;
    }
  }

  render() {
    const {
      t,
      bookText,
      bookPages,
      currentTool,
      answerText,
      visibleAnswerSets,
      answerStepperMode,
      visibleLinkAreas,
      isRendered,
      sidebarAnchor,
      isSolutionsPageVisible,
      pageNumbersToShow,
      renderScroll,
    } = this.props;
    const { isLoading, viewPortTransform, pagesRendered, renderState } = this.state;

    const { isSinglePage, isRightPage } = this.getSpreadSpecs();
    const bookWidth = bookPages.reduce((sum, page) => sum + page?.width, 0);
    const bookHeight = bookPages[0]?.height || 0;

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div className={classNames('book', 'canvas-wrapper', { 'canvas-wrapper--scroll': renderScroll })} ref={this.container} data-testid={`book-${renderState}`} tabIndex={0}>
        {isLoading && (
          <div className="pbb-book-loader">
            <img src={spinner} alt={t('book.loading')} />
          </div>
        )}
        <div className="canvas-wrapper-content">
          <canvas id="the-fabric-canvas" className={classNames({ 'pbb-book-canvas--loading': isLoading })} />
          {bookText.length > 0 && isRendered && (currentTool === Tools.POINTER || currentTool === Tools.TEXT_MARKER) && (
            <TextLayer
              id="book"
              text={bookText}
              answerText={answerText}
              viewPortTransform={viewPortTransform}
              bookDimensions={{ width: bookWidth, height: bookHeight }}
              linkAreas={visibleLinkAreas}
              answerSets={answerStepperMode ? visibleAnswerSets : []}
              isSinglePage={isSinglePage}
              isRightPage={isRightPage}
              saveTextHighlights={this.saveTextHighlights}
              renderTempHighlights={this.renderTempHighlights}
              pageWidth={bookPages[0]?.width || 0}
              sidebarAnchor={sidebarAnchor}
              scrollContainer={this.container.current}
            />
          )}
          {bookText.length > 0 && isRendered && currentTool === Tools.POINTER && isSolutionsPageVisible && viewPortTransform && (
            <TextLayer
              id="solutions"
              text={bookText}
              answerText={answerText}
              bookDimensions={{ width: bookWidth, height: bookHeight }}
              isSinglePage={isSinglePage}
              isRightPage={isRightPage}
              saveTextHighlights={this.saveTextHighlights}
              renderTempHighlights={this.renderTempHighlights}
              pageWidth={bookPages[0]?.width || 0}
              sidebarAnchor={sidebarAnchor}
              viewPortTransform={[
                viewPortTransform[0],
                viewPortTransform[1],
                viewPortTransform[2],
                viewPortTransform[3],
                viewPortTransform[4] - bookWidth * viewPortTransform[0],
                viewPortTransform[5],
              ]}
              scrollContainer={this.container.current}
            />
          )}
          {viewPortTransform && bookPages.length ? (
            <AnnotationLayers
              bookPages={bookPages}
              currentTool={currentTool}
              isLoading={Boolean(isLoading)}
              pageNumbersToShow={pageNumbersToShow}
              pagesRendered={pagesRendered}
              viewportTransform={viewPortTransform}
              scrollContainer={this.container.current}
            />
          ) : null}
        </div>
        {this.renderConfirmationModal()}
      </div>
    );
  }
}
const getListOfExternalDigibookIds = (linkAreas, digibookId) =>
  (linkAreas || [])
    .filter(linkarea => linkarea.linkType === 'digibook')
    .map(linkarea => linkarea.digibookLink.digibook)
    .filter(id => id !== digibookId)
    .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

const mapStateToProps = (state, { digibookId, visibleLinkAreas }) => ({
  currentTool: getCurrentTool(state),
  answerShapes: getAnswerShapesForCurrentPages(state),
  zoomLevel: getZoomLevel(state),
  zoomToFitMode: getZoomToFitMode(state),
  tools: getTools(state),
  answerStepperMode: getAnswerStepperMode(state),
  totalPages: getTotalPagesForDigibook(state),
  externalDigibookIds: getListOfExternalDigibookIds(visibleLinkAreas, digibookId),
  isRendered: getIsRendered(state),
  isSolutionsPageVisible: shouldRenderSolutionsPage(state),
  renderScroll: shouldRenderScroll(state),
});

const mapDispatchToProps = {
  setPage: setCurrentPage,
  userHasPanned: disableZoomToFitMode,
  revealArea: revealAnswerAreaFor,
  hideArea: hideAnswerAreaFor,
  markingCreated: createMarking,
  markingsCreated: createMarkings,
  openMedialinksForLinkArea: linkAreaWithMedialinksClicked,
  bookRendered: bookLayerRendered,
  stepperReveal: revealStepperAnswer,
  stepperHide: hideStepperAnswer,
  setZoom: setZoomLevel,
  setTool: setCurrentTool,
};

Book.propTypes = {
  // Own Props
  digibookId: string.isRequired,
  dimensions: shape({
    height: number.isRequired,
    width: number.isRequired,
  }).isRequired,
  pageNumbersToShow: arrayOf(number),
  bookPages: array,
  answerPages: array,
  manualPages: array,
  manualMargins: shape({
    top: number.isRequired,
    left: number.isRequired,
    height: number.isRequired,
    width: number.isRequired,
  }),
  activeDrawerAnchorSide: string,
  visibleLinkAreas: arrayOf(
    shape({
      linkType: string,
      url: string,
      digibookLink: shape({
        digibook: string.isRequired,
        pageNumber: number.isRequired,
      }),
    }),
  ),
  scaleCanvasToFit: bool,

  // Connected Props
  currentTool: string,
  answerShapes: objectOf(
    arrayOf(
      shape({
        width: number,
        height: number,
        left: number,
        top: number,
        action: oneOf([shapeActions.HIDE, shapeActions.REVEAL]),
        id: string,
      }),
    ),
  ),
  visibleAnswerSets: arrayOf(
    shape({
      id: string,
      shape: shape({
        top: number,
        left: number,
        size: number,
      }),
      answers: arrayOf(
        oneOfType([
          shape({
            top: number,
            left: number,
            width: number,
            height: number,
          }),
          shape({
            members: arrayOf({
              top: number,
              left: number,
              width: number,
              height: number,
            }),
          }),
        ]),
      ),
    }),
  ),
  zoomLevel: number.isRequired,
  zoomToFitMode: bool,
  tools: shape({
    pencil: shape({
      color: shape({
        id: oneOfType([string, number]).isRequired,
        color: string.isRequired,
        order: number.isRequired,
      }),
      size: string.isRequired,
    }),
    marker: shape({
      color: shape({
        id: oneOfType([string, number]).isRequired,
        color: string.isRequired,
        order: number.isRequired,
      }),
      size: string.isRequired,
    }),
    text_marker: shape({
      color: shape({
        id: oneOfType([string, number]).isRequired,
        color: string.isRequired,
        order: number.isRequired,
      }),
    }),
    classic_eraser: shape({}),
    selection_eraser: shape({}),
    ruler: shape({}),
  }).isRequired,
  answerStepperMode: bool,
  totalPages: number,
  setPage: func.isRequired,
  userHasPanned: func.isRequired,
  revealArea: func.isRequired,
  hideArea: func.isRequired,
  markingCreated: func.isRequired,
  markingsCreated: func.isRequired,
  openMedialinksForLinkArea: func.isRequired,
  bookRendered: func.isRequired,
  stepperReveal: func.isRequired,
  stepperHide: func.isRequired,
  setZoom: func.isRequired,
  setTool: func.isRequired,
  t: func.isRequired,
  sidebarAnchor: oneOf([AnchorPosition.LEFT, AnchorPosition.RIGHT, AnchorPosition.TOP]).isRequired,
  externalDigibookIds: arrayOf(String).isRequired,
  disableAnswerLayerBlend: bool,
  bookText: arrayOf(object),
  answerText: arrayOf(object),
  isRendered: bool.isRequired,
  swipeLeft: func.isRequired,
  swipeRight: func.isRequired,
  captureSameDigibook: func.isRequired,
  captureExternalDigibook: func.isRequired,
  isSolutionsPageVisible: bool,
  renderScroll: bool.isRequired,
  isMobileHeight: bool.isRequired,
};

Book.defaultProps = {
  pageNumbersToShow: [0],
  currentTool: undefined,
  answerShapes: {},
  zoomToFitMode: true,
  bookPages: [],
  answerPages: [],
  manualPages: [],
  manualMargins: undefined,
  activeDrawerAnchorSide: undefined,
  answerStepperMode: false,
  totalPages: undefined,
  disableAnswerLayerBlend: false,
  visibleLinkAreas: [],
  visibleAnswerSets: [],
  bookText: [],
  answerText: [],
  isSolutionsPageVisible: false,
  scaleCanvasToFit: false,
};

const ConnectedBook = connect(mapStateToProps, mapDispatchToProps)(Book);

export default withTranslation()(ConnectedBook);
