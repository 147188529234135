import get from 'get-value';
import { createSelector } from 'reselect';

import ViewMode from '../enums/ViewMode';
import { calculateSpreadForPage } from '../utils/spreadUtilities';
import { getCurrentDigibook, getTotalPagesForDigibook } from './digibooks';
import { getCurrentPageNumber, getIsMobileWidth, getViewMode } from './navigation';
import { getIsSolutionsPageVisible } from './player';

/**
 * @description Decides based on viewMode and other parameters if the book should be rendered as spread
 * @returns {Boolean}
 */
export function shouldRenderSpread(state) {
  const viewMode = getViewMode(state);
  if (viewMode === ViewMode.SPREAD) {
    const isSolutionsView = getIsSolutionsPageVisible(state);
    const isMobileView = getIsMobileWidth(state);

    return !isMobileView && !isSolutionsView;
  }

  return false;
}

/**
 * @description Decides if the book solutionsPage should be rendered
 * @returns {Boolean}
 */
export function shouldRenderSolutionsPage(state) {
  const isSolutionsView = getIsSolutionsPageVisible(state);
  const isMobileView = getIsMobileWidth(state);

  return !isMobileView && isSolutionsView;
}

/**
 * @description Decides based on viewMode and other parameters if the book should be rendered as scroll
 * @returns {Boolean}
 */
export function shouldRenderScroll(state) {
  return getViewMode(state) === ViewMode.SCROLL && !getIsMobileWidth(state);
}

/**
 * @deprecated Filtering out the pages of the spread causes us to lose necessary context.
 */
export const getAmountOfPagesToShow = state => {
  if (shouldRenderSpread(state)) {
    const currentPageNumber = getCurrentPageNumber(state);
    const totalPages = getTotalPagesForDigibook(state);
    return calculateSpreadForPage(totalPages, currentPageNumber).length;
  }

  return 1;
};

/**
 * @deprecated Filtering out the pages of the spread causes us to lose necessary context.
 */
export const getPageNumbersToShow = createSelector(
  //
  getCurrentPageNumber,
  getAmountOfPagesToShow,
  (currentPage, amountOfPagesToShow) => {
    if (currentPage === undefined) return [];

    if (amountOfPagesToShow === 2) {
      return currentPage % 2 === 0 ? [currentPage, currentPage + 1] : [currentPage - 1, currentPage];
    }

    return [currentPage];
  },
);

/**
 * @description Gets the current spread according to the current page.
 */
export const getCurrentSpread = createSelector(
  //
  getCurrentPageNumber,
  getTotalPagesForDigibook,
  (currentPage, totalPages) => calculateSpreadForPage(totalPages, currentPage),
);

/**
 * @description Hides the non visible pages from the current spread.
 */
export const getVisiblePages = createSelector(getCurrentPageNumber, getCurrentSpread, shouldRenderSpread, (currentPage, spread, renderSpread) => {
  if (currentPage === undefined) return undefined;

  if (renderSpread) return spread;

  return spread.map(page => (page === currentPage ? page : null));
});

export const getAnswerLayerShownFor = state => get(state, ['navigation', 'answerLayerShownFor']);

export const getAnswerLayerPagesToShow = createSelector(
  //
  getPageNumbersToShow,
  getAnswerLayerShownFor,
  shouldRenderSolutionsPage,
  getCurrentDigibook,
  (pageNumbersToShow, answerLayerPages, renderSolutionsPage, digibook) => {
    if (pageNumbersToShow.length === 0) return pageNumbersToShow;

    const pagesWhereAnswersShouldBeShown = pageNumbersToShow.filter(page => digibook.allowedRanges?.some(range => range.from <= page && range.to >= page));

    if (renderSolutionsPage && pagesWhereAnswersShouldBeShown.length > 0) return pageNumbersToShow;

    return pageNumbersToShow.map(pn => (answerLayerPages.shapes.some(x => x.page === pn) ? pn : null));
  },
);

export const getAnswerShapesForCurrentPages = createSelector(
  //
  getPageNumbersToShow,
  getAnswerLayerShownFor,
  (pageNumbersToShow, answerLayerMapper) =>
    pageNumbersToShow.reduce((acc, page) => {
      acc[page] = answerLayerMapper.shapes.filter(shape => shape.page === page);
      return acc;
    }, {}),
);

const getMarkingsForPage = state => get(state, ['tools', 'markingsForPage']);

const inPagesFilter = pn => o => o.pages.includes(pn);

export const getMarkingsForCurrentPages = createSelector(
  //
  getPageNumbersToShow,
  getMarkingsForPage,
  (pageNumbersToShow, markings = []) => markings.filter(inPagesFilter(pageNumbersToShow[0])).map(marking => marking.shape),
);
