import CloseIcon from '@pelckmans/business-components/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Flyout({ visible, onHide, className = undefined, children, 'data-testid': dataTestId }) {
  const { t } = useTranslation();

  return (
    <div className={classNames('flyout', 'flyout-from-bottom', { hidden: !visible }, className)} data-testid={dataTestId} aria-hidden={!visible}>
      <button type="button" className="flyout__close" onClick={onHide} title={t('options.close')}>
        <CloseIcon />
      </button>
      <div className="flyout__content">{children}</div>
    </div>
  );
}

Flyout.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
};

export default Flyout;
