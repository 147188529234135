import { bool, number, shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentModuleId, getModuleForDigibook } from '../../../../../../selectors/digibooks';
import { isTeacherAlike } from '../../../../../../selectors/user';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import OptionsMenuActions from './OptionsMenuActions';
import SlideSetInfo from './SlideSetInfo';
import UnlicenseSlideSetListItem from './UnlicensedSlideSetListItem';
import useCloseDrawerOnMobile from '../hooks/useCloseDrawerOnMobile';

function LicensedSlideSetListItem({ slideSet, isTeacher }) {
  const { id, name } = slideSet;

  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  const handleAnalyticsAndCloseDrawer = useCloseDrawerOnMobile(() => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });
  });

  return (
    <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
      <div className="pbb-list__link-wrap">
        <a
          onClick={handleAnalyticsAndCloseDrawer}
          className="pbb-list__link pbb-flex-wrap"
          title={name}
          href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SlideSetInfo slideSet={slideSet} />
        </a>
        {isTeacher && (
          <div className="pbb-list__actions">
            <DrawerOptionsMenu>
              <OptionsMenuActions slideSet={slideSet} />
            </DrawerOptionsMenu>
          </div>
        )}
      </div>
    </li>
  );
}

const SlideSetListItem = ({ slideSet }) => {
  const isTeacher = useSelector(isTeacherAlike);
  const { licenses } = useSelector(getModuleForDigibook);

  if (licenses.hasDocumentLicenseForTeacher || licenses.hasDemoProductLicenseForTeacher || !isTeacher) {
    return <LicensedSlideSetListItem slideSet={slideSet} isTeacher={isTeacher} />;
  }

  return <UnlicenseSlideSetListItem slideSet={slideSet} isTeacher={isTeacher} />;
};

SlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
};

LicensedSlideSetListItem.propTypes = {
  ...SlideSetListItem.propTypes,
  isTeacher: bool.isRequired,
};

export default SlideSetListItem;
