import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import LayerNames from '../../../../enums/layername';
import Errors from '../../../../enums/error-messages';

import PageImageProvider from '../../../../services/page-image-provider';
import PageTextProvider from '../../../../services/page-text-provider';

import { ImageCacheContext } from '../../context/image-cache-context';
import { TextCacheContext } from '../../context/text-cache-context';

import { getIsManualVisible } from '../../../../selectors/player';
import useManualPageProvider from './use-manual-page-provider';
import { EMPTY_ARRAY } from '../../../../utils/stable-default-props';

function usePageProvider(digibookId, systemToken, totalPages, pageNumbersToShow, answersToShow) {
  const [state, setState] = useState({ images: EMPTY_ARRAY, text: EMPTY_ARRAY, manual: EMPTY_ARRAY });
  const imageCache = useContext(ImageCacheContext);
  const textCache = useContext(TextCacheContext);

  const imageProvider = useRef(new PageImageProvider(digibookId, systemToken, imageCache));
  const textProvider = useRef(new PageTextProvider(digibookId, systemToken, textCache));

  const isCover = pageNumbersToShow.includes(0);
  const isBackCover = pageNumbersToShow.includes(totalPages + 1);

  const bookPromise = useMemo(() => {
    if (pageNumbersToShow.length < 1) return Promise.resolve(EMPTY_ARRAY);

    if (isCover) {
      return Promise.all([imageProvider.current.getImagesForPages(LayerNames.COVER, [1]), textProvider.current.getTextForPages(LayerNames.COVER, [1])]);
    }

    if (isBackCover) {
      return Promise.all([imageProvider.current.getImagesForPages(LayerNames.BACKCOVER, [1]), textProvider.current.getTextForPages(LayerNames.BACKCOVER, [1])]);
    }

    return Promise.all([
      imageProvider.current.getImagesForPages(LayerNames.BOOKLAYER, pageNumbersToShow),
      textProvider.current.getTextForPages(LayerNames.BOOKLAYER, pageNumbersToShow),
    ]);
  }, [pageNumbersToShow, isCover, isBackCover]);

  const answerPromise = useMemo(async () => {
    const filteredAnswersToShow = answersToShow.filter(x => x !== null);

    if (filteredAnswersToShow.length === 0) return Promise.resolve(EMPTY_ARRAY);

    const [imagesForPages, textForPages] = await Promise.all([
      imageProvider.current.getImagesForPages(LayerNames.ANSWERLAYER, filteredAnswersToShow),
      textProvider.current.getTextForPages(LayerNames.ANSWERLAYER, answersToShow),
    ]);

    return [
      answersToShow.map(page => (page ? imagesForPages.find(x => Number(x.dataset.page) === page) : null)),
      answersToShow.map(page => (page ? textForPages.find(x => Number(x.pageNum) === page) : null)),
    ];
  }, [answersToShow]);

  const isManualVisible = useSelector(getIsManualVisible);

  const bookToManualMap = useManualPageProvider();

  const [from, to] = pageNumbersToShow;

  const manualFromPage = bookToManualMap[from];
  const manualToPage = bookToManualMap[to];

  const manualPromise = useMemo(async () => {
    if (!isManualVisible || pageNumbersToShow.length < 1 || isCover || isBackCover) return Promise.resolve(EMPTY_ARRAY);

    const manualPagesToShow = [manualFromPage || null, manualToPage || null];

    const manualPages = await Promise.all(
      manualPagesToShow.map(async manualPage => {
        if (manualPage === null) return null;

        const [manualImage] = await imageProvider.current.getImagesForPages(LayerNames.MANUAL, [manualPage]);

        return manualImage;
      }),
    );

    return [manualPages];
  }, [isManualVisible, pageNumbersToShow, isCover, isBackCover, manualFromPage, manualToPage]);

  useEffect(() => {
    let current = true;

    async function retrieveImages() {
      try {
        const [
          [newBookImages = EMPTY_ARRAY, newBookText = EMPTY_ARRAY],
          [newAnswerImages = EMPTY_ARRAY, newAnswerText = EMPTY_ARRAY],
          [newManualImages = EMPTY_ARRAY],
        ] = await Promise.all([bookPromise, answerPromise, manualPromise]);

        if (!current) return;

        setState({ images: [newBookImages, newAnswerImages], text: [newBookText.map(x => x.json), newAnswerText.map(x => x?.json)], manual: newManualImages });
      } catch (e) {
        if (e.message !== Errors.IMAGE_LOAD_CANCELLED) throw e;
      }
    }

    retrieveImages();

    return () => {
      current = false;
    };
  }, [bookPromise, answerPromise, manualPromise]);

  return state;
}

export default usePageProvider;
