import { TextAnnotations } from '@pelckmans/business-components/components/text-annotations';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import useVisibleAnnotations from './hooks/use-annotations';
import useSpreadSpecs from './hooks/use-spread-specs';

import { LANGUAGE } from '../../../../../constants/constants';
import CustomColorContext from '../../../../../contexts/CustomColorContext';
import tools from '../../../../../enums/tools';
import useTextAnnotationTranslations from '../../../../../hooks/useTextAnnotationTranslations';
import { shouldRenderSolutionsPage } from '../../../../../selectors/rendering';
import { getCurrentTool } from '../../../../../selectors/tools';
import { UserSettingsContext } from '../../../context/user-settings-context';

export default function TextAnnotationLayer({ viewportTransform, pageWidth, pageHeight, saveTimeout, scrollContainer }) {
  const isSolutionsPageVisible = useSelector(shouldRenderSolutionsPage);
  const currentTool = useSelector(getCurrentTool);
  const { isSinglePage, isRightPage, isStandalonePage } = useSpreadSpecs();
  const { showMarkings, sidebarAnchor } = useContext(UserSettingsContext);
  const [spreadAnnotations, addAnnotation, editAnnotation, setSelectedAnnotationId, selectedAnnotation, removeAnnotation] = useVisibleAnnotations({
    saveTimeout,
    pageWidth,
    pageHeight,
  });

  const { customColors, saveCustomColor } = useContext(CustomColorContext);

  const translations = useTextAnnotationTranslations();

  if (!showMarkings) return null;

  return (
    <TextAnnotations
      viewportTransform={viewportTransform}
      pageWidth={pageWidth}
      pageHeight={pageHeight}
      annotations={spreadAnnotations}
      addAnnotation={addAnnotation}
      editAnnotation={editAnnotation}
      setSelectedAnnotationId={setSelectedAnnotationId}
      selectedAnnotation={selectedAnnotation}
      isSinglePage={isSinglePage}
      isRightPage={isRightPage}
      isStandalonePage={isStandalonePage}
      isSolutionsPageVisible={isSolutionsPageVisible}
      removeAnnotation={removeAnnotation}
      sidebarAnchor={sidebarAnchor}
      customColors={customColors}
      saveCustomColor={saveCustomColor}
      lang={LANGUAGE}
      scrollContainer={scrollContainer}
      isInteractive={currentTool === tools.TEXT_ANNOTATION}
      translations={translations}
      canvasWidth={scrollContainer.clientWidth}
    />
  );
}

TextAnnotationLayer.propTypes = {
  viewportTransform: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  saveTimeout: PropTypes.number,
  scrollContainer: PropTypes.object,
};
