import React, { useContext, useLayoutEffect, useState } from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ToolsPanel, { TOOLS } from './components/ToolsPanel';
import { getCurrentTool, getTools } from '../../../../../../selectors/tools';
import Tools from '../../../../../../enums/tools';
import { setCurrentTool } from '../../../../../../actions/tools';
import FlyOutContext, { defaultValue as defaultFlyOutContextValue } from '../../../dock/flyOutContext';

const itemsCount = 1;

const CONFIGURABLE_TOOLS = TOOLS.map(({ tool }) => tool);

export default function ToolsButton({ calculateTotalWidth }) {
  const { t } = useTranslation();
  const currentTool = useSelector(getCurrentTool);
  const dispatch = useDispatch();

  const [selectedTool, setSelectedTool] = useState(CONFIGURABLE_TOOLS.includes(currentTool) ? currentTool : Tools.PENCIL);
  const currentColor = useSelector(getTools)[selectedTool]?.color?.color;

  const flyOutContextValue = useContext(FlyOutContext);
  if (!flyOutContextValue === defaultFlyOutContextValue) throw new Error('<ToolsButton/> must be rendered inside a <FlyOutContext.Provider/>');

  const { openFlyOutType, setOpenFlyOutType } = flyOutContextValue;

  useLayoutEffect(() => {
    calculateTotalWidth(itemsCount);

    return () => {
      calculateTotalWidth(0);
    };
  }, [calculateTotalWidth]);

  function closeFlyOut() {
    setOpenFlyOutType(undefined);
  }

  function toggleFlyOut() {
    if (openFlyOutType !== 'tools') {
      setOpenFlyOutType('tools');
    } else {
      closeFlyOut();
    }
  }

  function handleClick() {
    dispatch(setCurrentTool(selectedTool));
    toggleFlyOut();
  }

  function handleToolClick(tool) {
    setSelectedTool(tool);
    dispatch(setCurrentTool(tool));
  }

  const { Icon, toolTipKey } = TOOLS.find(({ tool }) => tool === selectedTool);

  function handleStopDrawing() {
    closeFlyOut();
    dispatch(setCurrentTool(Tools.POINTER));
  }

  const isDrawingActive = CONFIGURABLE_TOOLS.includes(currentTool);

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={classNames({ 'tools-panel-toggle-button--active': isDrawingActive })}
        style={isDrawingActive ? { '--accent-color': currentColor } : undefined}
        data-testid="toggle-panel-button"
        title={t(toolTipKey)}
      >
        <Icon />
      </button>
      <ToolsPanel
        isVisible={openFlyOutType === 'tools'}
        onToolClick={handleToolClick}
        onClose={closeFlyOut}
        onEraseAllAnnotations={handleStopDrawing}
        onStopDrawing={handleStopDrawing}
      />
    </>
  );
}

ToolsButton.propTypes = {
  calculateTotalWidth: func.isRequired,
};
