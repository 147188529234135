import React, { useRef, useEffect, useContext } from 'react';
import { arrayOf, func, number, object, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentMathTool } from '../../../../../selectors/tools';
import { getCurrentDigibook } from '../../../../../selectors/digibooks';
import { getPlayerMode } from '../../../../../selectors/player';

import Tools from '../../../../../enums/tools';
import Ruler from './ruler/Ruler';
import DraftingCompass from './drafting-compass/DraftingCompass';
import useMathToolPositioning from './useMathToolPositioning';
import PlayerMode from '../../../../../enums/playerMode';
import SetSquare from './set-square/SetSquare';
import { shouldRenderScroll } from '../../../../../selectors/rendering';
import { MobileSizeContext } from '../../../context/MobileSizeContext';
import { setCurrentMathTool } from '../../../../../actions/tools';
import { getViewMode } from '../../../../../selectors/navigation';
import ViewMode from '../../../../../enums/ViewMode';

const A4_LANDSCAPE_DIMENSIONS = { height: 210, width: 297 };

export default function MathTools({
  bookDimensions,
  pagesRendered,
  viewPortTransform,
  onDraftingCompassTempDraw,
  onDraftingCompassFinishedDrawing,
  setFreeDrawingStrategy,
  scrollContainer,
}) {
  const dispatch = useDispatch();
  const currentMathTool = useSelector(getCurrentMathTool);
  const isScrollViewMode = useSelector(shouldRenderScroll);
  const currentViewMode = useSelector(getViewMode);
  const { isMobileHeight, isMobileWidth } = useContext(MobileSizeContext);

  const ref = useRef(null);

  const { setCalculateCenter, setResetPosition } = useMathToolPositioning({
    currentMathTool,
    ref,
    viewPortTransform,
    bookDimensions,
    isScrollViewMode,
    scrollContainer,
  });

  const pageDimensions = useSelector(state => {
    const playerMode = getPlayerMode(state);

    return playerMode === PlayerMode.BOOK ? getCurrentDigibook(state).pageDimensions : A4_LANDSCAPE_DIMENSIONS;
  });

  // Hot reload fallback
  const pixelsPerMM = bookDimensions.width / pageDimensions.width || 5.6;

  useEffect(() => {
    if (currentViewMode === ViewMode.PAGE) return;
    setResetPosition(true);
  }, [currentViewMode, isMobileWidth, isScrollViewMode, pagesRendered, setResetPosition]);

  useEffect(() => {
    if (isMobileHeight && currentMathTool) {
      dispatch(setCurrentMathTool(undefined));
    }
  }, [currentMathTool, dispatch, isMobileHeight]);

  return (
    <div id="math-tools" ref={ref} style={{ transform: `scale(${viewPortTransform[0]})` }} data-pixels-per-mm={pixelsPerMM} data-testid="math-tools">
      {currentMathTool === Tools.RULER && (
        <Ruler pixelsPerMM={pixelsPerMM} viewPortTransform={viewPortTransform} setFreeDrawingStrategy={setFreeDrawingStrategy} setCalculateCenter={setCalculateCenter} />
      )}
      {currentMathTool === Tools.SET_SQUARE && (
        <SetSquare pixelsPerMM={pixelsPerMM} viewPortTransform={viewPortTransform} setFreeDrawingStrategy={setFreeDrawingStrategy} setCalculateCenter={setCalculateCenter} />
      )}
      {currentMathTool === Tools.DRAFTING_COMPASS && (
        <DraftingCompass
          pixelsPerMM={pixelsPerMM}
          viewPortTransform={viewPortTransform}
          ref={ref}
          onTempDraw={onDraftingCompassTempDraw}
          onDrawingFinished={onDraftingCompassFinishedDrawing}
          setCalculateCenter={setCalculateCenter}
          scrollContainer={scrollContainer}
        />
      )}
    </div>
  );
}

MathTools.propTypes = {
  bookDimensions: shape({
    width: number,
    height: number,
  }).isRequired,
  pagesRendered: arrayOf(number).isRequired,
  viewPortTransform: arrayOf(number).isRequired,
  onDraftingCompassTempDraw: func.isRequired,
  onDraftingCompassFinishedDrawing: func.isRequired,
  setFreeDrawingStrategy: func.isRequired,
  scrollContainer: object,
};
