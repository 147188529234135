import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import { arrayOf, number, func } from 'prop-types';
import Tools from '../../../../../../enums/tools';
import Actions from './components/Actions';
import Backdrop from './components/Backdrop';
import DegreesIndicator from '../components/DegreesIndicator';
import DistanceMarkings from './components/DistanceMarkings';
import Numbers from './components/Numbers';
import useRulerResizing from './useRulerResizing';
import useRulerRotating from './useRulerRotating';

import RulerDrawer from './rulerFreeDrawingStrategy';
import { MATH_TOOL_STROKE_WIDTH } from '../constants';

function calculateCenter(backdropElement) {
  return (mathToolsWrapperElement, isScrollViewMode, scrollContainer) => {
    if (!mathToolsWrapperElement || !backdropElement) return { offsetLeft: 0, offsetTop: 0 };

    const parentMathToolsWrapperEl = mathToolsWrapperElement.parentElement;
    const scrollContainerScrollTop = scrollContainer?.scrollTop || 0;
    const backdropClientRect = backdropElement.getBoundingClientRect();
    const mathToolsWrapperClientRect = mathToolsWrapperElement.getBoundingClientRect();
    const height = (isScrollViewMode ? window.innerHeight : parentMathToolsWrapperEl.clientHeight) - backdropClientRect.height - MATH_TOOL_STROKE_WIDTH;
    const width = parentMathToolsWrapperEl.clientWidth - backdropClientRect.width - MATH_TOOL_STROKE_WIDTH;

    const offsetTop = height / 2 + (mathToolsWrapperClientRect.top - backdropClientRect.top) + scrollContainerScrollTop;
    const offsetLeft = width / 2 + (mathToolsWrapperClientRect.left - backdropClientRect.left);

    return { offsetLeft, offsetTop };
  };
}

const amountOfMM = 150;
const minAmountOfMM = 10;
const maxAmountOfMM = 500;

export default function Ruler({ pixelsPerMM, viewPortTransform, setFreeDrawingStrategy, setCalculateCenter }) {
  const paddingLeft = pixelsPerMM * 14;
  const paddingRight = pixelsPerMM * 6;
  const linealsWidth = (amountOfMM + 1) * pixelsPerMM;
  const offset = pixelsPerMM * 6;
  const halfOfOffset = offset / 2;

  const rulerWidth = paddingLeft + linealsWidth + paddingRight;
  const rulerHeight = ((amountOfMM + 1) * pixelsPerMM) / 8;

  const baseWidth = rulerWidth + halfOfOffset;
  const baseHeight = rulerHeight + offset;

  const fontSize = Math.round(baseHeight / 9);

  const minWidth = paddingLeft + (minAmountOfMM + 1) * pixelsPerMM + paddingRight + halfOfOffset;
  const maxWidth = paddingLeft + (maxAmountOfMM + 1) * pixelsPerMM + paddingRight + halfOfOffset;

  const svgRef = useRef(null);
  const backdropRef = useRef(null);

  const degreesRef = useRef(0);

  const { resize, width } = useRulerResizing({ viewPortTransform, minWidth, maxWidth, baseWidth, degrees: degreesRef.current });
  const { rotate, degrees, degreesDisplay } = useRulerRotating({ halfOfOffset, paddingLeft, ref: svgRef, scale: viewPortTransform[0], rulerWidth: width, rulerHeight });
  degreesRef.current = degrees;

  const maskWidth = useMemo(() => width - paddingLeft - paddingRight - halfOfOffset, [halfOfOffset, paddingLeft, paddingRight, width]);

  const stableCalculateCenter = useCallback((...args) => calculateCenter(backdropRef.current)(...args), []);

  useEffect(() => {
    setCalculateCenter(stableCalculateCenter);
    return () => setCalculateCenter(undefined);
  }, [setCalculateCenter, stableCalculateCenter]);

  useEffect(() => {
    setFreeDrawingStrategy(new RulerDrawer(viewPortTransform, svgRef.current));
    return () => {
      setFreeDrawingStrategy(undefined);
    };
  }, [setFreeDrawingStrategy, viewPortTransform]);

  return (
    <svg
      className="math-tool"
      height={baseHeight}
      id={Tools.RULER}
      data-testid={Tools.RULER}
      role="img"
      viewBox={`0 -${halfOfOffset} ${width - MATH_TOOL_STROKE_WIDTH} ${baseHeight}`}
      width={width - MATH_TOOL_STROKE_WIDTH}
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      data-angle={degrees}
      data-origin-x={paddingLeft}
      style={{ transformOrigin: `${paddingLeft}px ${halfOfOffset}px`, transform: `rotate(${degrees}deg)` }}
    >
      <g ref={backdropRef}>
        <Backdrop height={rulerHeight} width={width - halfOfOffset} />
        <Actions x={width - offset - MATH_TOOL_STROKE_WIDTH} rulerHeight={rulerHeight} onResize={resize} onRotate={rotate} pixelsPerMM={pixelsPerMM} halfOfOffset={halfOfOffset} />
      </g>
      <g data-drag>
        <DegreesIndicator xPos={paddingLeft / 2} yPos={rulerHeight / 2} radius={Math.round(rulerHeight / 5)} degrees={degreesDisplay} fontSize={fontSize - 1} />
        <Numbers pixelsPerMM={pixelsPerMM} height={rulerHeight} paddingLeft={paddingLeft} fontSize={fontSize} maskWidth={maskWidth} maxAmountOfMM={maxAmountOfMM} />
        <DistanceMarkings pixelsPerMM={pixelsPerMM} height={rulerHeight} paddingLeft={paddingLeft} maskWidth={maskWidth} maxAmountOfMM={maxAmountOfMM} />
      </g>
    </svg>
  );
}

Ruler.propTypes = {
  pixelsPerMM: number.isRequired,
  viewPortTransform: arrayOf(number).isRequired,
  setFreeDrawingStrategy: func.isRequired,
  setCalculateCenter: func.isRequired,
};
